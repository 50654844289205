import { toDataSourceRequestString } from '@progress/kendo-data-query';

export default {
    data: () => ({
        columns: [],
        filter: {
            logic: 'and',
            filters: [],
        },
        sort: null,
        dataResult: {
          data: [],
          total: 10,
        },
        showEntityModal: false,
        currentItem: null,
        salesStatus: null,
        total: 0,
        pageSize: 10,
        take: 10,
        skip: 0,
    }),
    methods: {
        async getData() {
            const { items, total, salesStatus } = await this.prepareData();
            this.dataResult = { data: items, total };
            if (salesStatus) {
                this.salesStatus = salesStatus;
            }

            this.total = total;
        },
        async prepareData() {
            try {
                const fetchParams = this.prepareFetchParams();
                const {
                    data: { data },
                } = await this.fetchData(...fetchParams);

                return data;
            } catch (e) {}
        },
        prepareFetchParams() {
            return [
                this.page,
                this.pageSize,
                toDataSourceRequestString({
                    filter: this.filter,
                    sort: this.sort,
                }),
            ];
        },
        dataStateChange({ data: { take, skip, filter, sort } }) {
            this.take = take;
            this.skip = skip;
            this.filter = filter;
            this.sort = sort;

            this.getData();
        },
        sortChange({ sort }) {
            this.sort = sort;
            this.skip = 0;
            this.getData();
        },
        updateTable(reset = false, previousPage = false) {
            if (reset) {
                this.skip = 0;
            }
            
            if (previousPage) {
              this.skip = this.skip - this.take
            }

            this.getData();
        },
        filterChange(ev) {
            this.filter = ev.filter;
            this.skip = 0;

            this.getData();
        },
        expandChange(event) {
            this.$set(
                event.dataItem,
                event.target.$props.expandField,
                event.value
            );
        },
        setCurrentItem(item) {
            this.currentItem = item;
        },
        clearCurrentItem() {
            this.currentItem = null;
        },
        selectFilter(field, value, operator = 'eq') {
            if (value === null || value === undefined) {
                this.removeFilter(field);
            } else {
                this.setFilter(field, operator, value);
            }
            this.updateTable(true);
        },
        initializeFilter() {
            this.filter = {
                logic: 'and',
                filters: [],
            };
        },
        setFilter(field, operator, value) {
            if (!this.filter) {
                this.initializeFilter();
            }

            let index = this.filter.filters.findIndex(
                item => item.field == field
            );

            if (index !== -1) {
                this.filter.filters[index] = {
                    field,
                    operator,
                    value,
                };
            } else {
                this.filter.filters.push({
                    field,
                    operator,
                    value,
                });
            }
        },
        setMultipleFilter(field, obj) {
            if (!this.filter) {
                this.initializeFilter();
            }
            this.removeFilter(field);
            this.removeFilter(field);
            obj.map(item => this.filter.filters.push(item));
        },
        clearFilters() {
            if (this.filter.filters.length) {
                this.filter.filters = []
            }
        },
        removeFilter(field) {
            let index = this.filter.filters.findIndex(
                item => item.field == field
            );

            if (index !== -1) {
                this.filter.filters.splice(index, 1);
            }
        },
        clearDatesFilters() {
            this.filter.filters = this.filter.filters.filter((filter) => filter.field !== 'date')
        },
        entityStored() {
            this.showEntityModal = false;
            this.updateTable();
        },
    },
    computed: {
        page() {
            return this.skip ? Math.round(this.skip / this.take) : 0;
        },
        crumbs() {
            let routes = this.$route.matched;
            let lastRoute = routes.slice(-1)[0];

            return [
                {
                    disabled: true,
                    text: 'Главная',
                    iconClasses: 'la la-home',
                    href: '',
                },
                {
                    link: true,
                    text: lastRoute.name,
                    // href: lastRoute.path
                    to: lastRoute.path,
                },
            ];
        },
    },
};
